<template>
  <img alt="Cloud logo" src="./assets/logo.png">
  <ProxyPage msg="Welcome to Your Vue.js App"/>
  <br>
  <a-space wrap aria-setsize="8">
  <a-button type="primary" @click="info">Info</a-button>
  <a-button type="primary" @click="gohome">Home</a-button>
  </a-space>
</template>

<script>
import ProxyPage from './components/ProxyPage.vue'
import { message } from 'ant-design-vue';

export default {
  name: 'App',
  components: {
    ProxyPage
  },
  methods: {
    info(){
     message.info("Created by haonewa,Powered by Vercel")
    },
    gohome()
    {
      window.open("https://haonewa.top")
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
img{
  height: 100px;
}
</style>
