<script>
export default {
  data() {
    return {
      msg: 'Hproxy!',
      inputmsg: 'link',
      url: '',
      content: ''
    }
  },
  methods: {
    golink() {      
      if (this.url) {
        var modurl = this.url.replace(/^https?:\/\//i, "");
        const modifiedUrl = 'https://proxy.haonewa.top/proxy/' + modurl;
        window.open(modifiedUrl, '_blank');
   }
   else{
    alert("请输入链接！")
   }
  },
  qsearch() {      
      if (this.content) {
        const modifiedUrl = 'https://proxy.haonewa.top/proxy/www.google.com/search?q=' + this.content;
        window.open(modifiedUrl, '_blank');
   }
   else{
    alert("请输入搜索内容！")
   }
  }
}
}
</script>

<template>
  <h1>{{ msg }}</h1>
  <a-space direction="vertical">
  <a-input-search
      v-model:value="url"
      placeholder="输入要加速的链接"
      enter-button="前往"
      size="large"
      @search="golink"
    />
  <a-input-search
      v-model:value="content"
      placeholder="快速搜索"
      enter-button="搜索"
      size="large"
      @search="qsearch"
    />
  </a-space>
  <br>
</template>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
  color: azure ;
}
a {
  color: #42b983;
}
</style>
